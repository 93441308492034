// @import url("https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic");

@import url("https://fonts.googleapis.com/css?family=Raleway:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic");

* {
  margin: 0;
  border: 0;
  padding: 0;

  // font-family: "Poppins", "Courier", monospace;
  font-family: "Raleway", "Courier", monospace;
  -webkit-tap-highlight-color: transparent;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
  text-decoration: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1;
  text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: inherit;
  line-height: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  width: 100%;
}

.highlight-link {
  padding: 3px 4px;
  color: #007bff !important;
}

/* -------------------- */
