.projectCardWrapper {
  display: flex;
  border-radius: 20px;
  padding: 10px 15px;
  min-height: 330px;

  background-image: url("../../../imgs/backgrounds/grayGradient/grayGradient.png");
  background-image: image-set(url("../../../imgs/backgrounds/grayGradient/grayGradient.avif") type("image/avif"), url("../../../imgs/backgrounds/grayGradient/grayGradient.webp") type("image/webp"));

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .projectImg {
    align-self: center;
    border-radius: 10px;
    padding: 5px;
    width: 320px;
    height: 300px;
    box-shadow: 0 0 3px rgba($color: #000, $alpha: 0.4);
    object-fit: contain;
  }

  .projectInfo {
    margin-left: 20px;

    .projectHeader {
      margin: 20px 0;
      font-weight: 500;
      font-size: 1.9rem;
      text-align: center;
    }

    .projectDescription {
      font-size: 1.2rem;
      line-height: 1.3rem;
    }
  }
}

@media (max-width: 1200px) {
  .projectCardWrapper {
    .projectImg {
    }

    .projectInfo {
      .projectHeader {
      }

      .projectDescription {
      }
    }
  }
}

@media (max-width: 768px) {
  .projectCardWrapper {
    flex-direction: column;
    padding: 0;
    width: 100%;

    .projectImg {
      align-self: flex-start;
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 180px;
      box-shadow: 0 0 3px rgba($color: #000, $alpha: 0.4);
    }

    .projectInfo {
      margin-left: 0;
      padding: 0 10px 10px;
      width: auto;

      .projectHeader {
        margin: 10px 0;
        font-size: 1.5rem;
      }

      .projectDescription {
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}
