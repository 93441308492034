@import "../../../styles/index";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 55px 40px;
  height: 270px;
  max-width: 550px;

  background-image: url("../../../imgs/backgrounds/slice1/slice1.png");
  background-image: image-set(url("../../../imgs/backgrounds/slice1/slice1.avif") type("image/avif"), url("../../../imgs/backgrounds/slice1/slice1.webp") type("image/webp"));

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .sliderImg {
    align-self: flex-start;
    margin-top: -15px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }

  .textContent {
    margin-left: 56px;
    max-width: 268px;

    .sliderHeader {
      margin-bottom: 40px;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 24px;
      color: #fff;
    }
  }
}

@media (max-width: 1200px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 15px;
    width: 308px;
    height: 316px;
    max-width: auto;
    text-align: center;

    .sliderImg {
      align-self: center;
      margin: 0 0 20px;
      width: 120px;
      height: 120px;
    }

    .textContent {
      margin-left: 0;
      max-width: auto;

      .sliderHeader {
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }
}
