@import "../../../styles/";

.slick-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev,
.slick-next {
}

.slick-prev::before,
.slick-next::before {
  content: none;
}

.slick-dots li button::before {
  margin-top: 8px;
  font-size: 10px;
  line-height: 10px;
}

// fix for two active dots
.slick-dots li button:focus::before {
  opacity: 0.25;
}

.slick-dots li.slick-active button:focus::before,
.slick-dots li button:hover::before {
  opacity: 1;
}

@media (max-width: 768px) {
  .slick-list {
    margin: 0 -7px;
  }

  .slick-slide > div {
    padding: 0 7px;
  }
}
