@import "../../../styles";

.cardWrapper {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #000;
  border-radius: 20px;
  padding: 53px 10px;
  width: 320px;
  height: 257px;
  color: #fff;

  background-image: url("../../../imgs/backgrounds/slice1/slice1.png");
  background-image: image-set(url("../../../imgs/backgrounds/slice1/slice1.avif") type("image/avif"), url("../../../imgs/backgrounds/slice1/slice1.webp") type("image/webp"));

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .cardIcon {
    width: 70px;
    height: 70px;

    // for inverting color
    filter: invert(100%) sepia(5%) saturate(2%) hue-rotate(240deg) brightness(111%) contrast(100%);
    -webkit-user-drag: none;
    user-select: none;
  }

  .descriptionWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 7px;
    height: 96px;
  }

  .headerText {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 36px;
    text-align: center;
  }

  &:nth-child(even) {
    background-image: url("../../../imgs/backgrounds/slice2/slice2.png");
    background-image: image-set(url("../../../imgs/backgrounds/slice2/slice2.avif") type("image/avif"), url("../../../imgs/backgrounds/slice2/slice2.webp") type("image/webp"));

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

@media (max-width: 1200px) {
  .cardWrapper {
    width: 280px;
    height: 207px;
  }
}
