@import "../../styles";

// section home
.home {
  height: 100vh;

  background-image: url("../../imgs/backgrounds/mainBackground/mainBackground.png");
  background-image: image-set(url("../../imgs/backgrounds/mainBackground/mainBackground.avif") type("image/avif"), url("../../imgs/backgrounds/mainBackground/mainBackground.webp") type("image/webp"));

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .homeContent {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 100%;

    .info {
      width: 525px;
      color: #fff;

      .headerText {
        margin-bottom: 20px;
        font-weight: 800;
        font-size: 3rem;
        line-height: 72px;
      }
    }

    .qrCode {
      width: 340px;
      height: 340px;
    }

    .qrCodeLetter {
      width: 660px;
      height: 400px;
    }
  }
}

@media (max-width: 1200px) {
  .home {
    .homeContent {
      flex-direction: column;
      justify-content: center;

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        order: 2;
        text-align: center;

        .headerText {
          font-size: 1.5rem;
          line-height: 36px;
        }
      }

      .qrCode {
        order: 1;
        margin-bottom: 10px;
        width: 300px;
        height: 300px;
      }

      .qrCodeLetter {
        order: 1;
        margin-bottom: 10px;
        width: 550px;
        height: 300px;
      }
    }
  }
}

@media (max-width: 768px) {
  .home {
    .homeContent {
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        order: 2;
        width: 350px;
        color: #fff;

        .headerText {
          margin-bottom: 10px;
          font-weight: 600;
          font-size: 1.5rem;
          line-height: 36px;
        }
      }

      .qrCodeLetter {
        width: 360px;
        height: 250px;
      }
    }
  }
}

// end

// section Услуги

.servicesWrapper {
  padding: 150px 130px;

  // background: url("../../imgs/backgrounds/mainBackgroundBlack&White&Transparent.png");
  // background: #f4f4f4;
  // background: radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);

  background-image: url("../../imgs/backgrounds/grayGradient/grayGradient.png");
  background-image: image-set(url("../../imgs/backgrounds/grayGradient/grayGradient.avif") type("image/avif"), url("../../imgs/backgrounds/grayGradient/grayGradient.webp") type("image/webp"));

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .cardsWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }
}

@media (max-width: 1200px) {
  .servicesWrapper {
    padding: 80px 50px;
  }
}

// section CEO

.ceoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 150px 7vw;

  background-image: url("../../imgs/backgrounds/mainBackground_reversed/mainBackground_reversed.png");
  background-image: image-set(
    url("../../imgs/backgrounds/mainBackground_reversed/mainBackground_reversed.avif") type("image/avif"),
    url("../../imgs/backgrounds/mainBackground_reversed/mainBackground_reversed.webp") type("image/webp")
  );

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .ceoImg {
    border-radius: 50%;
    width: 400px;
    height: 400px;
  }

  .ceoContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;

    .ceoHeader {
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 54px;
      color: #fff;
    }

    .listOfCeoBenefits {
      .ceoBenefitWrapper {
        .ceoBenefit {
          position: relative;
          margin-left: 18px;

          .listDot {
            content: "";
            position: absolute;
            left: -15px;
            top: 50%;
            border-radius: 50%;
            width: 11px;
            height: 11px;
            background-color: #fff;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .ceoWrapper {
    padding-inline: 0;
  }
}

@media (max-width: 1200px) {
  .ceoWrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 7vw;

    .ceoImg {
      margin-bottom: 20px;
      width: 300px;
      height: 300px;
    }

    .ceoContent {
      align-items: center;
      margin-left: 0;
      max-width: 500px;

      .ceoHeader {
        margin-bottom: 12px;
        font-size: 2rem;
        line-height: 36px;
        text-align: center;
        color: #fff;
      }

      .listOfCeoBenefits {
        .ceoBenefitWrapper {
          .ceoBenefit {
            margin-left: 0;

            .listDot {
              left: -12px;
              width: 8px;
              height: 8px;
            }
          }
        }
      }
    }
  }
}

// end

// section Сотрудники

.workersWrapper {
  padding: 150px 107px;

  background-image: url("../../imgs/backgrounds/grayGradient/grayGradient.png");
  background-image: image-set(url("../../imgs/backgrounds/grayGradient/grayGradient.avif") type("image/avif"), url("../../imgs/backgrounds/grayGradient/grayGradient.webp") type("image/webp"));

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1200px) {
  .workersWrapper {
    padding-block: 80px;
  }
}

@media (max-width: 768px) {
  .workersWrapper {
    padding: 30px 50px 50px;
  }
}

// end

// section Проекты

.projectsWrapper {
  position: relative;
  padding: 80px 15vw;

  background-image: url("../../imgs/backgrounds/mainBackground/mainBackground.png");
  background-image: image-set(url("../../imgs/backgrounds/mainBackground/mainBackground.avif") type("image/avif"), url("../../imgs/backgrounds/mainBackground/mainBackground.webp") type("image/webp"));

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .projectRef {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 1px;
    height: 1px;
  }

  .projectsHeader {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 2.9rem;
    text-align: center;
    color: #fff;
  }

  .projectCardsWrapper {
    display: flex;
    column-gap: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;

    .projectDescriptionIndent {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 1300px) {
  .projectsWrapper {
    padding: 40px 5vw;

    .projectsHeader {
      margin-bottom: 30px;
      font-size: 2.4rem;
    }

    .projectCardsWrapper {
      gap: 20px;
    }
  }
}

@media (max-width: 768px) {
  .projectsWrapper {
    .projectRef {
      top: 30%;
    }

    .projectsHeader {
      margin-bottom: 10px;
      font-size: 2.4rem;
    }

    .projectCardsWrapper {
      gap: 20px;
    }
  }
}
