@import "../../../styles";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  color: #808080;
  background: #000;
  padding-block: 25px 20px;

  .networks {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 20px;

    .network {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }

      .faIcon {
        transition: all 0.5s ease;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .copyrightInfo {
    font-size: 1rem;
    text-align: center;

    .copyrightNumbers {
      font-family: "Courier", monospace;
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    .copyrightInfo {
      padding-inline: 10px;
    }

    .separator {
      display: block;
    }
  }
}
