.navigationWrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px 0;
  width: 100%;
  color: #fff;
  backface-visibility: hidden;
  transition: all 0.4s ease;

  .logo {
    font-weight: 600;
    font-size: 3rem;
    line-height: 72px;
    color: #fff;
    cursor: pointer;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navigation__item {
      margin-right: 40px;
      border: 2px solid transparent;
      padding: 0 0 3px;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 30px;
      text-align: center;
      transition: all 0.2s ease;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        border-bottom: 2px solid #fff;
      }
    }
  }
}

.scrolledNavigation {
  padding-top: 0;
  background: rgba($color: #090975, $alpha: 0.5);
}

@media (max-height: 620px), (max-width: 1200px) {
  .navigationWrapper {
    padding: 10px 20px;

    .logo {
      font-size: 2rem;
      line-height: 36px;
    }

    .navigation {
      .navigation__item {
        margin-right: 7px;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }

  .scrolledNavigation {
    padding-top: 10px;
  }
}

@media (max-width: 768px) {
  .navigationWrapper {
    justify-content: center;

    .logo {
      display: none;
    }

    .navigation {
      .navigation__item {
      }
    }
  }
}
